import axiosIns from '@/libs/axios'

export default {
  fintechService: (() => axiosIns.get('accounting/fintech-service/fintech')),
  paymentService: (fintechId => axiosIns.get(`accounting/payment-service/fintech/${fintechId}`)),
  save: (payload => axiosIns.post('accounting/fintech-service', payload)),
  update: ((Id, payload) => axiosIns.put(`accounting/fintech-service/${Id}`, payload)),
  getAll: (id => axiosIns.get(`accounting/fintech-service/read/${id}`)),
  delete: (Id => axiosIns.delete(`accounting/fintech-service/${Id}`)),
}
