<template>
  <b-card>

    <b-modal
      id="fintech-service-provider-modal"
      :title="`${updateId ? 'Update' : 'Add'} Fintech Service Provider`"
      :ok-title="`${updateId ? 'Update' : 'Save'}`"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleFintechFormSubmit"
      @cancel="resetFintech"
      @close="resetFintech"
      @ok.prevent
    >
      <b-form @submit.prevent>
        <b-row>
          <!-- Service Name -->
          <b-col cols="12">
            <b-form-group
              label="Service Name"
              label-cols-md="4"
              :state="serviceIdState"
              invalid-feedback="Service Id is Required"
            >
              <v-select
                v-model="fintechFormData.serviceId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Service"
                :options="fintechServiceOptions"
                :reduce="val => val.id"
                :state="serviceIdState"
              />
            </b-form-group>
          </b-col>
          <div
            v-if="fintechServiceCategoryId && fintechServiceCategoryId == 5"
            class="col-12 p-0"
          >
            <b-col
              cols="12"
            >
              <b-form-group
                label="API Key"
                label-cols-md="4"
                :state="apiKeyState"
                invalid-feedback="API Key is Required"
              >
                <b-form-input
                  v-model="fintechFormData.apiKey"
                  placeholder="Enter API Key"
                  :state="apiKeyState"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                label="API Secret"
                label-cols-md="4"
                :state="apiSecretState"
                invalid-feedback="API Secret is Required"
              >
                <b-form-input
                  v-model="fintechFormData.apiSecret"
                  placeholder="Enter API Secret"
                  :state="apiSecretState"
                />
              </b-form-group>
            </b-col>
          </div>
          <div
            v-else-if="fintechServiceCategoryId && fintechServiceCategoryId != 5"
            class="col-12 p-0"
          >
            <b-col
              cols="12"
            >
              <b-form-group
                label="Endpoint URL"
                label-cols-md="4"
                invalid-feedback="End Point Url is Required"
                :state="endpointUrlState"
              >
                <b-form-input
                  v-model="fintechFormData.endpointUrl"
                  placeholder="Enter Endpoint URL"
                  :state="endpointUrlState"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Callback URL"
                label-cols-md="4"
                :state="callbackUrlState"
                invalid-feedback="Call Back Url is Required"
              >
                <b-form-input
                  v-model="fintechFormData.callbackUrl"
                  placeholder="Enter Callback URL"
                  :state="callbackUrlState"
                />
              </b-form-group>
            </b-col>
          </div>
          <b-col cols="12">
            <b-form-group
              label="Status"
              label-cols-md="4"
              :state="statusState"
              invalid-feedback="Status is Required"
            >
              <b-form-radio-group
                v-model="fintechFormData.status"
                :options="fintechStatusOptions"
                name="status-radio"
                :state="statusState"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Fintech Service Provider
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          variant="outline-primary"
          style="text-transform: capitalize"
          @click="openModal"
        >
          Add Fintech Service Provider
        </b-button>
      </b-form-group>
      <b-form-group class="pr-0 col-lg-4 col-md-4">
        <v-select
          v-model="fintechId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          placeholder="Select Fintech"
          :options="fintechOptions"
          :reduce="val => val.id"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <span v-else-if="props.column.field === 'status'">
          {{ props.formattedRow[props.column.field] == 1 ? 'Active' : 'Inactive' }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Audit Activity Modal -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormRadioGroup, BForm, BFormSelect, BPagination, BButton, BFormInput, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { RefreshCwIcon } from 'vue-feather-icons'

import FemsToastrService from '@/@service/utils/FemsToastr.service'
import FintechServiceProviderService from '@/@service/api/accounting/fintech/FintechServiceProvider.service'

import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BButton,
    BFormRadioGroup,
    vSelect,
    RefreshCwIcon,
    BLink,
    ActivityModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      updateId: null,
      columns: [
        {
          label: 'Service',
          field: 'payment_service.name',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      fintechId: null,
      fintechServiceCategoryId: null,
      fintechFormData: {
        serviceId: null,
        status: 1,
        apiKey: null,
        apiSecret: null,
        endpointUrl: null,
        callbackUrl: null,
      },
      fintechStatusOptions: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 0 },
      ],
      fintechOptions: [],
      fintechServiceOptions: [],
      serviceTypeState: null,
      serviceIdState: null,

      apiKeyState: null,
      apiSecretState: null,

      endpointUrlState: null,
      callbackUrlState: null,

      statusState: null,

      // Activity Log Data
      tag: 'fintech-service-provider',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    fintechId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getFintechGridData()
      }
    },
    'fintechFormData.serviceId': function (newVal, oldVal) {
      if (newVal === null) {
        this.fintechServiceCategoryId = null
      }

      if (newVal && newVal !== oldVal) {
        setTimeout(() => {
          // console.log(this.fintechServiceOptions)
          this.fintechServiceCategoryId = this.fintechServiceOptions
            .find(({ id }) => id === this.fintechFormData.serviceId).service_category_id
        }, 2000)
      }

      if (this.fintechServiceCategoryId && this.fintechServiceCategoryId === 5) {
        this.fintechFormData.endpointUrl = null
        this.fintechFormData.callbackUrl = null
        this.endpointUrlState = null
        this.callbackUrlState = null
      }
      if (this.fintechServiceCategoryId && this.fintechServiceCategoryId !== 5) {
        this.fintechFormData.apiKey = null
        this.fintechFormData.apiSecret = null
        this.apiKeyState = null
        this.apiSecretState = null
      }
    },
  },
  created() {
    this.getFintechs()
  },
  methods: {
    serviceTypeChange(value) {
      if (value) {
        this.serviceTypeState = null
      }
    },
    getFintechs() {
      FintechServiceProviderService.fintechService().then(res => {
        this.fintechOptions = res.data.data
        if (this.fintechOptions.length > 0) {
          this.fintechId = this.fintechOptions[0].id
          // this.getFintechGridData()
        }
      })
    },
    getFintechGridData() {
      if (!this.fintechId) {
        this.rows = []
        return
      }
      FintechServiceProviderService.getAll(this.fintechId).then(res => {
        if (res.data.status) {
          this.rows = res.data.data
        } else {
          this.rows = []
        }
      })
        .catch(err => {
          this.rows = []
          FemsToastrService.err(err.message)
        })
    },
    getFintechService() {
      FintechServiceProviderService.paymentService(this.fintechId).then(res => {
        this.fintechServiceOptions = res.data.data
      })
    },
    handleFintechFormSubmit() {
      if (this.updateId) {
        this.updateFintech()
      } else {
        this.saveFintech()
      }
    },
    validate() {
      this.serviceIdState = null
      this.apiKeyState = null
      this.apiSecretState = null
      this.endpointUrlState = null
      this.callbackUrlState = null
      this.statusState = null

      if (!this.fintechFormData.serviceId) {
        this.serviceIdState = false
      }

      if (this.fintechServiceCategoryId && this.fintechServiceCategoryId === 5) {
        if (this.fintechFormData.apiKey === null || this.fintechFormData.apiKey === '') {
          this.apiKeyState = false
        }
        if (this.fintechFormData.apiSecret === null || this.fintechFormData.apiSecret === '') {
          this.apiSecretState = false
        }
      }
      if (this.fintechServiceCategoryId && this.fintechServiceCategoryId !== 5) {
        if (this.fintechFormData.endpointUrl === null || this.fintechFormData.endpointUrl === '') {
          this.endpointUrlState = false
        }
        if (this.fintechFormData.callbackUrl === null || this.fintechFormData.callbackUrl === '') {
          this.callbackUrlState = false
        }
      }
      if (this.serviceIdState === false || ((this.apiKeyState === false && this.apiSecretState === false) || (
        this.endpointUrlState === false && this.callbackUrlState === false))) {
        return false
      }
      return true
    },
    saveFintech() {
      if (!this.validate()) {
        return
      }
      const params = {
        fintechId: this.fintechId,
        paymentServiceId: this.fintechFormData.serviceId,
        apiKey: this.fintechFormData.apiKey,
        apiSecret: this.fintechFormData.apiSecret,
        endpointUrl: this.fintechFormData.endpointUrl,
        callbackUrl: this.fintechFormData.callbackUrl,
        status: this.fintechFormData.status,
      }
      FintechServiceProviderService.save(params).then(res => {
        if (res.data.status) {
          this.resetFintech()
          this.getFintechGridData()
          this.$bvModal.hide('fintech-service-provider-modal')
          FemsToastrService.success(res.data.message)
        } else {
          FemsToastrService.error(res.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },
    updateFintech() {
      if (!this.validate()) {
        return
      }
      const params = {
        fintechId: this.fintechId,
        paymentServiceId: this.fintechFormData.serviceId,
        apiKey: this.fintechFormData.apiKey,
        apiSecret: this.fintechFormData.apiSecret,
        endpointUrl: this.fintechFormData.endpointUrl,
        callbackUrl: this.fintechFormData.callbackUrl,
        status: this.fintechFormData.status,
      }
      FintechServiceProviderService.update(this.updateId, params).then(res => {
        if (res.data.status) {
          this.resetFintech()
          this.getFintechGridData()
          this.$bvModal.hide('fintech-service-provider-modal')
          FemsToastrService.success(res.data.message)
        } else {
          FemsToastrService.error(res.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err.message)
        })
    },

    resetFintech() {
      this.updateId = null
      this.fintechFormData = {
        status: 1,
        apiKey: null,
        apiSecret: null,
        endpointUrl: null,
        callbackUrl: null,
      }

      this.serviceTypeState = null

      this.serviceIdState = null

      this.apiKeyState = null
      this.apiSecretState = null

      this.endpointUrlState = null
      this.callbackUrlState = null

      this.statusState = null

      this.fintechServiceCategoryId = null
    },
    openModal() {
      this.fintechFormData.serviceType = '1'
      this.fintechFormData.status = '1'

      if (!this.fintechId) {
        FemsToastrService.error('Please select a service category first!')
        return
      }
      this.resetFintech()
      this.getFintechService()
      this.$bvModal.show('fintech-service-provider-modal')
    },
    editRow(rowData) {
      // console.log(this.fintechId)
      this.getFintechService()
      // console.log(this.fintechServiceOptions)
      this.updateId = rowData.id
      this.fintechServiceCategoryId = rowData.payment_service.service_category_id
      this.fintechFormData.serviceId = rowData.payment_service.service_category_id
      this.fintechFormData = {
        id: rowData.id,
        serviceId: rowData.payment_service_id,
        apiKey: rowData.api_key || null,
        apiSecret: rowData.api_secret || null,
        callbackUrl: rowData.callback_url || null,
        endpointUrl: rowData.end_point_url || null,
        status: rowData.status,
      }

      this.$bvModal.show('fintech-service-provider-modal')
    },
    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          FintechServiceProviderService.delete(rowData.id).then(res => {
            if (res.status && res.data.status) {
              this.resetFintech()
              this.getFintechGridData()
              FemsToastrService.success(res.data.message)
            } else {
              FemsToastrService.error(res.data.message)
            }
          })
        }
      })
    },
    refreshGrid() {
      if (this.fintechId) {
        this.getFintechGridData()
      }
    },

    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
